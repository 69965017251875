<template>
  <div class="content">
    <!-- 顶部导航开始 -->
    <div class="top">
      <!-- 顶部导航图片开始 -->
      <img src="../assets/img/schedule-top-img.png" alt="" />
      <!-- 顶部导航图片结束 -->
      <!-- 内容容器开始 -->
      <div class="container top-nav">
        <!-- 顶部导航开始 -->
        <my-header class="hidden-xs-only" active="4"></my-header>
        <language-change2
          class="hidden-sm-and-up"
          active="4"
        ></language-change2>
        <!-- 顶部导航结束 -->
      </div>
      <!-- 内容容器结束 -->
    </div>
    <!-- 顶部导航结束 -->
    <!-- 内容开始 -->
    <div class="middle">
      <div class="container">
        <!-- 面包屑开始 -->
        <div class="breadcrumb">
          <div class="breadcrumb-label">
            <div v-show="language == 'cn'">当前位置：</div>
            <div v-show="language == 'en'">Current location:</div>
          </div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">{{
              $t("message.topNav1")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/news' }">
              {{ $t("message.topNav5") }}</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{
              $t("message.scheduleDetail")
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 面包屑结束 -->
        <!-- 标题开始 -->
        <div class="middle-title">
          <div v-show="language == 'cn'">{{ msg.title }}</div>
          <div v-show="language == 'en'">{{ msg.enTitle }}</div>
        </div>
        <!-- 标题结束 -->
        <!-- 文章时间开始 -->
        <div class="middle-time">
          {{ msg.pubTime }}
        </div>
        <!-- 文章时间结束 -->
        <!-- 详情开始 -->
        <div class="middle-detail">
          <!-- 内容开始 -->
          <div class="content-box">
            <div v-show="language == 'cn'">
              <div class="middle-detail-msg" v-html="msg.content"></div>
            </div>
            <div v-show="language == 'en'">
              <div class="middle-detail-msg" v-html="msg.enContent"></div>
            </div>
          </div>
          <!-- 切换开始 -->
          <div class="middle-btn-content hidden-xs-only">
            <!-- 上一篇开始 -->
            <div class="middle-btn-box">
              <div
                class="middle-btn"
                @click="detailChange(msg.lastInfoId)"
                v-show="msg.lastInfoId"
              >
                <div class="middle-btn-label">
                  <span v-show="language == 'cn'">上一篇：</span>
                  <span v-show="language == 'en'">Previous：</span>
                </div>
                <div class="middle-btn-msg">
                  <span v-show="language == 'cn'">{{ msg.lastInfoName }}</span>
                  <span v-show="language == 'en'">{{
                    msg.lastInfoEnName
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 上一篇结束 -->
            <!-- 下一篇开始 -->
            <div class="middle-btn-box">
              <div
                class="middle-btn"
                @click="detailChange(msg.nextInfoId)"
                v-show="msg.nextInfoId"
              >
                <div class="middle-btn-label">
                  <span v-show="language == 'cn'">下一篇：</span>
                  <span v-show="language == 'en'">Next：</span>
                </div>
                <div class="middle-btn-msg">
                  <span v-show="language == 'cn'">{{ msg.nextInfoName }}</span>
                  <span v-show="language == 'en'">{{
                    msg.nextInfoEnName
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 下一篇结束 -->
          </div>
          <div class="middle-btn-sm hidden-sm-and-up">
            <!-- 上一篇开始 -->
            <div class="middle-btn-box">
              <div
                class="middle-btn"
                @click="detailChange(msg.lastInfoId)"
                v-show="msg.lastInfoId"
              >
                <div class="middle-btn-label">
                  <span v-show="language == 'cn'">上一篇：</span>
                  <span v-show="language == 'en'">Previous：</span>
                </div>
                <div class="middle-btn-msg">
                  <span v-show="language == 'cn'">{{ msg.lastInfoName }}</span>
                  <span v-show="language == 'en'">{{
                    msg.lastInfoEnName
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 上一篇结束 -->
            <!-- 下一篇开始 -->
            <div class="middle-btn-box">
              <div
                class="middle-btn"
                @click="detailChange(msg.nextInfoId)"
                v-show="msg.nextInfoId"
              >
                <div class="middle-btn-label">
                  <span v-show="language == 'cn'">下一篇：</span>
                  <span v-show="language == 'en'">Next：</span>
                </div>
                <div class="middle-btn-msg">
                  <span v-show="language == 'cn'">{{ msg.nextInfoName }}</span>
                  <span v-show="language == 'en'">{{
                    msg.nextInfoEnName
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 下一篇结束 -->
          </div>
          <!-- 切换结束 -->
          <!-- 内容结束 -->
        </div>
        <!-- 详情结束 -->
      </div>
    </div>
    <!-- 内容结束 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  inject: ["reload"],
  data() {
    return {
      msg: {},
    };
  },
  mounted() {
    let id = this.$route.query.id;
    this.$axios
      .get(`api/getInfomation?id=${id}`, {})
      .then((res) => {
        if (res.data.code == 0) {
          this.msg = res.data.data;
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  methods: {
    detailChange(value) {
      this.$router.push({ path: "/newsDetail", query: { id: value } });
      this.reload();
    },
  },
  filters: {},
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style scoped>
.breadcrumb {
  color: #333333;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.middle-title {
  color: #333333;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 32px;
  margin: 20px 0 12px;
}

.middle-time {
  color: #999999;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}

.middle-detail {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-top: 1px solid #dddddd;
  line-height: 28px;
  margin: 20px 0;
  padding: 20px 0;
}

.middle-detail-msg {
  color: #333333;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 28px;
}

.middle-btn-content {
  background-color: #f7f9fb;
  margin: 60px 0 80px;
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
}

.middle-btn-box {
  width: 100%;
}

.middle-btn {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  margin-top: 20px;
  cursor: pointer;
}

.middle-btn-msg {
  flex: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media (min-width: 768px) {
  .middle-title {
    color: #333333;
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    text-align: center;
    margin: 40px 0 30px;
  }

  .middle-time {
    color: #999999;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    text-align: center;
  }

  .middle-detail {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    border-top: 1px solid #dddddd;
    line-height: 28px;
    margin: 40px 0;
    padding: 40px;
  }

  .middle-detail-msg {
    color: #333333;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
  }

  .middle-btn-box {
    width: 48%;
  }

  .middle-btn {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    margin-top: 0px;
    cursor: pointer;
  }

  .middle-btn-msg {
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.middle-btn-sm {
  border-top: 1px solid #dddddd;
  margin-top: 30px;
}

.content-box >>> img {
  width: 100%;
}
</style>
